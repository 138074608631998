import React from 'react';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  Card, CardContent, IconButton, Box, Avatar, Typography,
  Tooltip, Badge, useMediaQuery, useTheme, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { useRedirect, useDataProvider, useNotify, useLogout } from 'react-admin';
import SquadPlayersFieldDialog from './SquadPlayersFieldDialog';
import type { SquadPlayersFieldCardProps, Team, PlayerInSquad } from '../../../interfaces';
import { buildFifaIndexUrl } from '../../../utils';

const SquadPlayersFieldCard: React.FC<SquadPlayersFieldCardProps> = ({
  fifa,
  currentPlayer,
  playersInSquad,
  setPlayersInSquad,
  setRefresh,
  playerList,
}) => {
  const [teamAvatar, setTeamAvatar] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [hasSubs, setHasSubs] = React.useState<boolean>(false);
  const [dialogType, setDialogType] = React.useState<string>('');

  const theme = useTheme();
  const notify = useNotify();
  const logout = useLogout();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleDialogOpen = (type: string): void => {
    setDialogType(type);
    setOpenDialog(type === 'subs' ? hasSubs : true);
  };

  React.useEffect(() => {
    const getData = async (): Promise<Team> => {
      const data = await dataProvider.getOne('team', { id: currentPlayer.teamId });
      return data.data;
    };

    getData().then((respTeam: Team) => {
      setTeamAvatar(buildFifaIndexUrl(fifa, respTeam.fifaIndexId, 'teams'));
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 401) await logout();
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, [currentPlayer]);

  React.useEffect(() => {
    const filtered = playersInSquad.filter((player: PlayerInSquad) => player.sub === 1);
    setHasSubs(filtered.length > 0);
  }, [playersInSquad]);

  return (
    <>
      <Card
        raised
        sx={{
          width: isSmall ? '100%' : '95%',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title="Replace with bench player">
              <IconButton size="small" onClick={() => handleDialogOpen('subs')}>
                <ManageAccountsIcon
                  sx={{
                    color: hasSubs ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.26)',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Replace with new player">
              <IconButton size="small" onClick={() => handleDialogOpen('new')}>
                <ChangeCircleIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Badge
                badgeContent={
                  <Typography sx={{ color: '#fff' }}>
                    {currentPlayer.rating}
                  </Typography>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color="primary"
              >
                <Avatar
                  src={buildFifaIndexUrl(fifa, currentPlayer.fifaIndexId, 'players')}
                  onClick={() => redirect('show', 'player', currentPlayer.id)}
                  sx={{
                    width: 70,
                    height: 70,
                    margin: 'auto',
                    cursor: 'pointer',
                  }}
                />
              </Badge>
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography align="center">
                {`${currentPlayer.firstName} ${currentPlayer.lastName ?? ''}`}
              </Typography>
              <Avatar
                src={teamAvatar}
                sx={{
                  width: 54,
                  height: 54,
                  margin: 'auto',
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
      {openDialog ? <SquadPlayersFieldDialog
        open={openDialog}
        setOpen={setOpenDialog}
        order={currentPlayer.order ?? 0}
        playersInSquad={playersInSquad}
        setPlayersInSquad={setPlayersInSquad}
        setRefresh={setRefresh}
        playerList={playerList}
        dialogType={dialogType}
      /> : null}
    </>
  );
};

export default SquadPlayersFieldCard;
