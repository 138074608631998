import React from 'react';
import type { AxiosError } from 'axios';
import {
  Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, NumberInput,
  FormDataConsumer, useDataProvider, useNotify, required,
  type CreateProps,
} from 'react-admin';
import FifaIndexInput from '../../components/inputs/FifaIndexInput';
import FifaInput from '../../components/inputs/FifaInput';
import type { Team } from '../../interfaces';

const PlayerCreate: React.FC<CreateProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [teamsCopy, setTeamsCopy] = React.useState<Team[]>([]);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const getTeams = async (): Promise<Team[]> => {
    const data = await dataProvider.getList('team', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getTeams().then((resp: Team[]) => {
      setTeams(resp);
      setTeamsCopy(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, []);

  React.useEffect(() => {
    if (parseInt(selectedVal) > 0) {
      const filtered = teamsCopy.filter((team: Team) => team.fifaId === parseInt(selectedVal));
      setTeams(filtered);
    }
  }, [selectedVal]);

  return (
    <Create redirect="show" sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleForm spacing={1}>
        <TextInput source="firstName" variant="filled" validate={required()} />
        <TextInput source="lastName" variant="filled" />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) => formData.fifaId !== undefined &&
            <AutocompleteInput
              source="teamId"
              choices={teams}
              variant="filled"
              validate={required()}
              optionText="name"
              optionValue="id"
              filterToQuery={(searchText: string) => ({ name: searchText })}
              shouldRenderSuggestions={(val: string) => {
                return val.trim().length > 2;
              }}
              noOptionsText="Type 3 characters"
              sx={{
                width: 219,
              }}
            />
          }
        </FormDataConsumer>
        <NumberInput
          source="age"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 15, max: 45 } }}
          sx={{
            width: 219,
          }}
        />
        <FifaIndexInput source="fifaIndexId" label="Fifa Index" />
        <ReferenceInput
          source="positionId"
          reference="position"
        >
          <AutocompleteInput
            source="name"
            variant="filled"
            validate={required()}
            optionText="name"
            optionValue="id"
            filterToQuery={(searchText: string) => ({ name: searchText })}
            shouldRenderSuggestions={(val: string) => {
              return val.trim().length > 1;
            }}
            noOptionsText="Type 1 character"
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
        <NumberInput
          source="rating"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0, max: 99 } }}
          sx={{
            width: 219,
          }}
        />
        <NumberInput
          source="rating_potential"
          label="Potentional rating"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0, max: 99 } }}
          sx={{
            width: 219,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default PlayerCreate;
