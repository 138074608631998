import React from 'react';
import {
  Create, SimpleForm, TextInput, NumberInput, FormDataConsumer,
  ReferenceInput, SelectInput, required, type CreateProps,
} from 'react-admin';
import FifaIndexInput from '../../components/inputs/FifaIndexInput';
import FifaInput from '../../components/inputs/FifaInput';
import RivalTeamInput from '../../components/inputs/RivalTeamInput';

const TeamCreate: React.FC<CreateProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  return (
    <Create redirect="show" sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <NumberInput
          source="budget"
          label="Budget (€)"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 1 } }}
        />
        <FifaIndexInput source="fifaIndexId" label="Fifa Index" />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) => formData.fifaId !== undefined &&
            <>
              <RivalTeamInput
                source="rivalTeamId"
                label="Rival team"
                view="create"
                fifa={formData.fifaId}
              />
              <ReferenceInput source="squadId" reference="squad" filter={{ fifaId: formData.fifaId }}>
                <SelectInput
                  source="name"
                  variant="filled"
                  label="Squad"
                  emptyText="Empty"
                  emptyValue="Empty"
                  defaultValue="Empty"
                  parse={(val: string) => val === 'Empty' ? 'Empty' : val}
                  sx={{
                    width: 219,
                  }}
                />
              </ReferenceInput>
            </>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default TeamCreate;
