import { type AxiosError } from 'axios';
import { format, addHours } from 'date-fns';
import { type AuthProvider } from 'react-admin';
import { Login, Logout } from '../services/AuthService';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await Login(username, password, 1).catch((err: AxiosError) => {
      if (err !== null) {
        if (err.response?.status === 401 || err.response?.status === 403) {
          throw err;
        }
      }
    });
    localStorage.setItem('logged-squad-builder', 'true');
    localStorage.setItem('refresh-button-date', format(addHours(new Date(), 22), 'yyyy/MM/dd HH:mm:ss'));
  },
  logout: async () => {
    localStorage.removeItem('logged-squad-builder');
    localStorage.removeItem('refresh-button-date');
    await Logout();
  },
  checkAuth: async () => {
    localStorage.getItem('logged-squad-builder') !== null
      ? await Promise.resolve()
      : await Promise.reject(new Error('User logged out'));
  },
  checkError: async (err: AxiosError) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      localStorage.removeItem('logged-squad-builder');
      localStorage.removeItem('refresh-button-date');
      await Promise.reject(new Error('User logged out'));
    }
    await Promise.resolve();
  },
  getPermissions: async () => {
    await Promise.resolve();
  },
};

export default authProvider;
