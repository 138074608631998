import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog, DialogTitle, DialogContent, IconButton, Box, Typography,
  Stack, Button,
} from '@mui/material';
import type { AxiosError } from 'axios';
import {
  Form, ReferenceInput, SelectInput, AutocompleteInput,
  NumberInput, required, useDataProvider, useNotify,
  useLogout, type RaRecord, type Identifier,
} from 'react-admin';
import type { EditSeasonDialogProps, EditSeasonForm } from '../../interfaces';
import * as API from '../../services/DataService';
import SeasonTrophiesInput from '../inputs/SeasonTrophiesInput';

const EditSeasonDialog: React.FC<EditSeasonDialogProps> = ({ open, setOpen, seasonData, fifa, setRefresh }) => {
  const [sameTrophies, setSameTrophies] = React.useState<boolean>(true);
  const notify = useNotify();
  const logout = useLogout();
  const dataProvider = useDataProvider();

  const updateManagedSeason = async (updatedData: RaRecord): Promise<RaRecord> => {
    const { id } = updatedData;
    const data = await dataProvider.update(
      'managed_season',
      {
        id,
        data: updatedData,
        previousData: updatedData,
      },
    );
    return data.data;
  };

  const updateSeasonTrophies = async (
    managedSeasonId: Identifier,
    seasonId: Identifier,
    trophies: Identifier[],
  ): Promise<string> => {
    const data = await API.UpdateSeasonTrophy(managedSeasonId, seasonId, trophies);
    return data;
  };

  const onSubmit = (formData: Partial<EditSeasonForm>): void => {
    const { careerId, finalPosition, id, points, seasonId, teamId, trophyIds, squadId } = formData;
    const managedSeasonData: RaRecord = {
      id: id ?? 0,
      teamId,
      seasonId,
      careerId,
      finalPosition,
      points,
      squadId,
    };
    updateManagedSeason(managedSeasonData).then((resp: RaRecord) => {
      const { id } = resp;
      if (sameTrophies) {
        setRefresh(true);
        setOpen(false);
      } else {
        updateSeasonTrophies(id, seasonId ?? 0, trophyIds ?? []).then((resp: string) => {
          setRefresh(true);
          setOpen(false);
        }).catch(async (err: AxiosError) => {
          if (err.response?.status === 401) await logout();
          if (err.response?.status === 500) notify('Server error', { type: 'error' });
        });
      }
    }).catch((err: AxiosError) => {
      if (err.response?.status === 409) notify('Duplicate entry', { type: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Edit season
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit} defaultValues={seasonData}>
          <Stack direction="column" spacing={0}>
            <ReferenceInput
              source="seasonId"
              reference="season"
              filter={{
                fifaId: fifa,
              }}
            >
              <SelectInput
                variant="filled"
                optionText="name"
                optionValue="id"
                validate={required()}
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
            <ReferenceInput
              source="teamId"
              reference="team"
              filter={{
                fifaId: fifa,
              }}
            >
              <AutocompleteInput
                source="name"
                variant="filled"
                validate={required()}
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
            <SeasonTrophiesInput
              source="id"
              seasonData={seasonData}
              fifa={fifa}
              setSameTrophies={setSameTrophies}
            />
            <NumberInput
              source="finalPosition"
              label="League position"
              variant="filled"
              validate={required()}
              min={1}
              max={30}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="points"
              variant="filled"
              min={0}
              max={150}
              sx={{
                width: 219,
              }}
            />
            <ReferenceInput
              source="squadId"
              reference="squad"
              filter={{
                fifaId: fifa,
              }}
            >
              <AutocompleteInput
                source="name"
                variant="filled"
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              startIcon={<SaveIcon />}
              sx={{
                color: '#fff',
              }}
            >
              Save
            </Button>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditSeasonDialog;
