import axios from 'axios';
import Config from '../Config';
import { type User } from '../interfaces';

const apiUrl = Config.api.url ?? '';
axios.defaults.withCredentials = true;

export const Login = async (username: string, password: string, admin: number): Promise<User[]> => {
  const body = { username, password, admin };
  const data = await axios.post(`${apiUrl}/user/login`, body);
  return data.data;
};

export const Logout = async (): Promise<string> => {
  const data = await axios.get(`${apiUrl}/user/logout`);
  return data.data;
};

export const RefreshToken = async (): Promise<string> => {
  const data = await axios.post(`${apiUrl}/user/refresh-token`);
  return data.data;
};
