import React from 'react';
import {
  CircularProgress, Box, TextField as MuiTextField,
  useMediaQuery, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import {
  Datagrid, FunctionField, ListContextProvider, Pagination,
  NumberField, ReferenceField, TextField, useList,
  useDataProvider, useShowContext, useNotify, type RaRecord,
} from 'react-admin';
import AvatarField from './AvatarField';
import type { EmbeddedListProps } from '../../interfaces';

const EmbeddedList: React.FC<EmbeddedListProps> = ({ resource, filterBy, orderBy }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [entities, setEntities] = React.useState<RaRecord[]>([]);
  const [filterName, setFilterName] = React.useState<string>('');
  const [entitiesCopy, setEntitiesCopy] = React.useState<RaRecord[]>([]);

  const listContext = useList({
    data: entities,
    perPage: 10,
  });
  const notify = useNotify();
  const { record } = useShowContext();
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterName(event.target.value);
    if (event.target.value.length === 0) setEntities(entitiesCopy);
    else {
      const filtered: RaRecord[] = entitiesCopy.filter(
        (player: RaRecord) => player.lastName !== null ? `${player.firstName} ${player.lastName}` : player.firstName
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()),
      );
      setEntities(filtered);
    }
  };

  React.useEffect(() => {
    const getPlayers = async (): Promise<RaRecord[]> => {
      const data = await dataProvider.getList(resource, {
        pagination: { page: 1, perPage: 100000 },
        sort: { field: orderBy, order: 'DESC' },
        filter: {},
      });
      const filtered = data.data.filter((entity: RaRecord) => entity[filterBy] === record?.id);
      return filtered;
    };

    getPlayers().then((resp: RaRecord[]) => {
      setEntities(resp);
      setEntitiesCopy(resp);
      setLoading(false);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, []);

  return (
    !loading
      ? <ListContextProvider value={listContext}>
        {resource === 'player'
          ? <MuiTextField
            label="Name"
            variant="filled"
            size="small"
            value={filterName}
            onChange={handleFilterName}
            sx={{
              width: 219,
            }}
          />
          : null
        }
        <Datagrid
          bulkActionButtons={false}
          rowClick="show"
          resource={resource}
        >
          {resource === 'player' &&
            <FunctionField label="Name" render={(record: RaRecord) => `${record.firstName} ${record.lastName ?? ''}`} />
          }
          {resource === 'player' && !isSmall
            ? <AvatarField source="fifaIndexId" type="players" size={50} label="" />
            : null
          }
          {resource === 'player' && <NumberField source="rating" />}
          {resource === 'player' && <ReferenceField source="positionId" reference="position" link="show">
            <TextField source="name" />
          </ReferenceField>}
        </Datagrid>
        <Pagination rowsPerPageOptions={[5, 10, 25]} />
      </ListContextProvider>
      : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress color="primary" size={50} />
      </Box>
  );
};

export default EmbeddedList;
