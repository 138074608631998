import axios, { type AxiosResponse } from 'axios';
import * as Dompurify from 'dompurify';
import { stringify } from 'querystring';
import { type Identifier, type RaRecord } from 'react-admin';
import Config from '../Config';
import type { GetListQueryParams, PlayerInSquad } from '../interfaces';
import { transformResource, transformData } from '../utils';

const apiUrl = Config.api.url ?? '';
axios.defaults.withCredentials = true;

/* REACT-ADMIN CRUD */
export const List = async (resource: string, query: GetListQueryParams): Promise<AxiosResponse> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-list?${Dompurify.sanitize(stringify(query))}`);
  return data;
};

export const GetOne = async (resource: string, id: Identifier): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-one?id=${Dompurify.sanitize(id.toString())}`);
  return data.data;
};

export const GetMany = async (resource: string, ids: Identifier[]): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-many?ids=${Dompurify.sanitize(ids.join(','))}`);
  return data.data;
};

export const GetManyReference = async (resource: string, query: GetListQueryParams): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-many-reference?${Dompurify.sanitize(stringify(query))}`);
  return data.data;
};

export const Update = async (resource: string, entity: Partial<RaRecord>): Promise<RaRecord> => {
  resource = transformResource(resource);
  entity = transformData(entity, resource);
  const data = await axios.put(`${apiUrl}/${resource}/update`, entity);
  return data.data;
};

export const Create = async (resource: string, entity: Partial<RaRecord>): Promise<RaRecord> => {
  resource = transformResource(resource);
  entity = transformData(entity, resource);
  const data = await axios.post(`${apiUrl}/${resource}/create`, entity);
  return data.data;
};

export const Delete = async (resource: string, id: Identifier): Promise<string> => {
  resource = transformResource(resource);
  const data = await axios.delete(`${apiUrl}/${resource}/delete?id=${Dompurify.sanitize(id.toString())}`);
  return data.data;
};

export const DeleteMany = async (resource: string, ids: Identifier[]): Promise<string> => {
  resource = transformResource(resource);
  const body = {
    ids,
  };
  const data = await axios.post(`${apiUrl}/${resource}/delete-many`, body);
  return data.data;
};

/* OTHER ENDPOINTS */
export const AddPlayersToSquad = async (players: RaRecord[]): Promise<RaRecord[]> => {
  const data = await axios.post(`${apiUrl}/playerinsquad/add-players-to-squad`, players);
  return data.data;
};

export const GetSquadPlayers = async (id: Identifier): Promise<PlayerInSquad[]> => {
  const data = await axios.get(`${apiUrl}/playerinsquad/get-squad-players?id=${Dompurify.sanitize(id.toString())}`);
  return data.data;
};

export const EditSquadPlayers = async (players: PlayerInSquad[]): Promise<string> => {
  const data = await axios.put(`${apiUrl}/playerinsquad/edit-squad-players`, players);
  return data.data;
};

export const GetLatestPlayers = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/player/get-latest`);
  return data.data;
};

export const GetTopRatedPlayers = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/player/get-top-rated`);
  return data.data;
};

export const UpdateSeasonTrophy = async (
  managedSeasonId: Identifier,
  seasonId: Identifier,
  trophies: Identifier[],
): Promise<string> => {
  const body = { managedSeasonId, seasonId, trophies };
  const data = await axios.post(`${apiUrl}/season-trophy/update-season-trophy`, body);
  return data.data;
};

export const HandleFavourite = async (playerId: Identifier, isFavourite: number): Promise<string> => {
  const body = {
    playerId,
    isFavourite,
  };
  const data = await axios.post(`${apiUrl}/player/handle-favourite`, body);
  return data.data;
};

export const ImportData = async (dataToCreate: Partial<RaRecord>, resource: string): Promise<string> => {
  const body = { dataToCreate };
  const data = await axios.post(
    `${apiUrl}/${resource === 'player' ? 'player/import-players' : 'team/import-teams'}`,
    body,
  );
  return data.data;
};

export const GetFifaInfo = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/fifa/get-info`);
  return data.data;
};
