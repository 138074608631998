import React from 'react';
import {
  Edit, SimpleForm, SelectInput, ReferenceInput, required,
  type EditProps, type RaRecord,
} from 'react-admin';
import SeasonInput from '../../components/inputs/SeasonInput';
import CustomEditTitle from '../../components/utils/CustomEditTitle';
import type { CreateSeasonFormErrors } from '../../interfaces';
import { isValidYearFormat } from '../../utils';

const SeasonEdit: React.FC<EditProps> = (props) => {
  const validateForm = (data: Partial<RaRecord>): CreateSeasonFormErrors => {
    const errors: CreateSeasonFormErrors = {};
    if (data.name === undefined || data.name.length !== 9) errors.name = 'Required';
    if (data.fifaId === undefined) errors.fifaId = 'Required';
    if (!isValidYearFormat(data.name)) errors.name = 'Required';
    return errors;
  };

  return (
    <Edit
      redirect="list"
      title={<CustomEditTitle resource="season" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1} validate={validateForm}>
        <SeasonInput source="name" />
        <ReferenceInput source="fifaId" reference="fifa" sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput
            label="FIFA"
            variant="filled"
            validate={required()}
            optionText="name"
            optionValue="id"
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default SeasonEdit;
