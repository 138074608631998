import React from 'react';
import { AppBar } from 'react-admin';
import CustomUserMenu from './CustomUserMenu';

const CustomAppBar: React.FC = () => {
  return (
    <AppBar color="primary" userMenu={<CustomUserMenu />} sx={{ color: '#fff' }} />
  );
};

export default CustomAppBar;
