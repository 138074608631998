import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Logout, UserMenu } from 'react-admin';
import FifaVersionDialog from './FifaVersionDialog';

const CustomUserMenu: React.FC = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  return (
    <>
      <UserMenu>
        <MenuList>
          <MenuItem onClick={() => setOpenDialog(true)}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>FIFA Version</ListItemText>
          </MenuItem>
          <Logout />
        </MenuList>
      </UserMenu>
      {openDialog ? (
        <FifaVersionDialog open={openDialog} setOpen={setOpenDialog} />
      ) : null}
    </>
  );
};

export default CustomUserMenu;
