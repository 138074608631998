import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography,
  IconButton, Button, CircularProgress, FormControl, InputLabel, Select,
  MenuItem, type SelectChangeEvent,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { useDataProvider, useNotify, type RaRecord } from 'react-admin';
import type { FifaVersionDialogProps } from '../../interfaces';

const FifaVersionDialog: React.FC<FifaVersionDialogProps> = ({ open, setOpen }) => {
  const storageSelectedFifa: string = localStorage.getItem('selected-fifa') ?? '0';
  const [fifas, setFifas] = React.useState<RaRecord[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedFifa, setSelectedFifa] = React.useState<string>(storageSelectedFifa);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event: SelectChangeEvent): void => {
    setSelectedFifa(event.target.value);
  };

  const updateCacheWithFifaId = (cacheKey: string, selectedFifa: string, removeCache: boolean): void => {
    const cache: string = localStorage.getItem(cacheKey) ?? '';
    if (cache.length > 0) {
      const cacheObj: Partial<RaRecord> = JSON.parse(cache);
      if (removeCache) {
        delete cacheObj.filter?.fifaId;
      } else {
        cacheObj.filter = {
          ...cacheObj.filter,
          fifaId: parseInt(selectedFifa),
        };
      }
      localStorage.setItem(cacheKey, JSON.stringify(cacheObj));
    }
  };

  const handleSubmit = (): void => {
    const removeCache = parseInt(selectedFifa) === 0;
    const keys = ['RaStore.player.listParams', 'RaStore.team.listParams'];
    keys.forEach(cacheKey => {
      updateCacheWithFifaId(cacheKey, selectedFifa, removeCache);
    });

    if (removeCache) localStorage.removeItem('selected-fifa');
    else localStorage.setItem('selected-fifa', selectedFifa);

    notify('FIFA successfully set', { type: 'success' });
    setOpen(false);
  };

  const getFifas = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('fifa', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getFifas()
      .then((resp: RaRecord[]) => {
        setFifas(resp);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server Error', { type: 'error' });
      });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Set FIFA version
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          <FormControl
            sx={{
              width: 219,
            }}
          >
            <InputLabel>FIFA</InputLabel>
            <Select
              value={selectedFifa}
              label="FIFA"
              onChange={handleChange}
            >
              <MenuItem value={0}>None</MenuItem>
              {fifas.map((fifa: RaRecord, index: number) => {
                return <MenuItem key={index} value={fifa.id}>{fifa.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              display: 'flex',
              jusitfyContent: 'flex-start',
            }}
          >
            <CircularProgress size={30} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
          sx={{
            color: '#fff',
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FifaVersionDialog;
