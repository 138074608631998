import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Dialog, DialogTitle, DialogContent, TextField as MuiTextField,
  Paper, IconButton, Typography, Box, Avatar, useMediaQuery,
  type Theme,
} from '@mui/material';
import {
  useList, ListContextProvider, Datagrid, FunctionField,
  NumberField, ReferenceField, TextField, Pagination,
  type RaRecord, type Identifier,
} from 'react-admin';
import type { SubstitutesDialogProps, Player } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';
import SmallLoader from '../utils/SmallLoader';

const SubstitutesDialog: React.FC<SubstitutesDialogProps> = ({
  open,
  setOpen,
  playersInSquad,
  setPlayersInSquad,
  playerList,
  fifa,
}) => {
  const [players, setPlayers] = React.useState<Player[]>([]);
  const [playersComplete, setPlayersComplete] = React.useState<Player[]>([]);
  const [subs, setSubs] = React.useState<RaRecord[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [filterValue, setFilterValue] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const listContext = useList({
    data: players,
    perPage: 10,
  });

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterValue(event.target.value);
    if (event.target.value.length === 0) setPlayers(playersComplete);
    else {
      const filtered = playersComplete.filter(
        (player: Player) => player.lastName.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()),
      );
      setPlayers(filtered);
    }
  };

  const handleRowClick = (id: Identifier, resource: string, record: RaRecord): false => {
    const newPlayer = {
      ...record,
      order: 11,
      sub: 1,
    };
    const newPlayers = [
      ...playersInSquad,
      newPlayer,
    ];
    setPlayersInSquad(newPlayers);
    setOpenDialog(false);
    return false;
  };

  const openPlayersDialog = (): void => {
    const filtered = playerList.filter((player: Player) =>
      !playersInSquad.some((playerInSquad) => player.id === playerInSquad.id),
    );
    setPlayers(filtered);
    setPlayersComplete(filtered);
    setLoading(false);
    setOpenDialog(true);
  };

  const removeSub = (id: Identifier): void => {
    const filtered = playersInSquad.filter((player: RaRecord) => player.id !== id);
    setPlayersInSquad(filtered);
  };

  React.useEffect(() => {
    const filtered = playerList.filter((player: Player) =>
      !playersInSquad.some((playerInSquad) => player.id === playerInSquad.id),
    );
    setPlayers(filtered);
    setPlayersComplete(filtered);
    const filteredSubs = playersInSquad.filter((player: RaRecord) => player?.sub === 1);
    setSubs(filteredSubs);
  }, [playersInSquad]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add subs</DialogTitle>
        <DialogContent>
          <Paper sx={{ backgroundColor: '#d6d6c2', padding: '10px' }}>
            {subs.map((sub: RaRecord, index: number) => {
              return <Paper
                data-id={sub.id}
                elevation={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                  width: isSmall ? 'auto' : '50%',
                  padding: '10px',
                }}
                key={index}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    src={buildFifaIndexUrl(fifa ?? 0, sub.fifaIndexId, 'players')}
                    sx={{
                      width: 40,
                      height: 40,
                      margin: 'auto',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                  >
                    {`${sub.firstName} ${sub.lastName ?? ''}`}
                  </Typography>
                </Box>
                <IconButton onClick={() => removeSub(sub.id)}>
                  <RemoveCircleIcon sx={{ color: 'red' }} />
                </IconButton>
              </Paper>;
            })}
            <IconButton
              onClick={openPlayersDialog}
              disabled={subs.length === 7}
              sx={{
                marginTop: '10px',
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Paper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add sub</DialogTitle>
        <DialogContent>
          {!loading
            ? <ListContextProvider value={listContext}>
              <MuiTextField
                label="Last name"
                variant="filled"
                size="small"
                value={filterValue}
                onChange={handleFilter}
              />
              <Datagrid
                resource="player"
                bulkActionButtons={false}
                rowClick={(id, resource, record) => handleRowClick(id, resource, record)}
              >
                <FunctionField label="Name" render={(record: Player) => `${record.firstName} ${record.lastName ?? ''}`} />
                <NumberField source="rating" />
                {!isSmall ? <ReferenceField source="positionId" reference="position" link={false}>
                  <TextField source="name" />
                </ReferenceField> : null}
              </Datagrid>
              <Pagination rowsPerPageOptions={[5, 10, 25]} />
            </ListContextProvider>
            : <SmallLoader />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubstitutesDialog;
